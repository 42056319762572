import { set } from "react-hook-form";
import { IEbayMessage } from "../../models/EbayMessage";
import Modal from "../../layouts/Modal";
import useEbayMessageChain from "../../data/useEbayMessagesChain";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { useState } from "react";
import { ArrowDownLeftIcon, ArrowDownTrayIcon, ArrowUpRightIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";

interface EbayMessageChainProps {
  open: boolean;
  setOpen: any;
  originMessage: IEbayMessage | undefined;
}

export default function EbayMessageChain({ originMessage, open, setOpen }: EbayMessageChainProps) {
  const { messages, sendReply } = useEbayMessageChain(originMessage);
  const [body, setBody] = useState("");

  const sendMessage = () => {
    sendReply.mutate({ body: body },
      {
        onSuccess: () => {
          setBody("");
        }
      })

  };

  if (messages.isLoading)
    return <Modal open={open} setOpen={setOpen} width={"max-w-[90vw]"}>
      <LoadingWheel />
    </Modal>;

  return <Modal open={open} setOpen={setOpen} width={"max-w-[90vw]"}>
    <div className="mt-4 bg-white overflow-auto">
      <h1 className="text-2xl font-semibold">Messages from {originMessage?.sendingUserID} </h1>
      <h2 className="text-md mb-6">{originMessage?.itemTitle}</h2>
      <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
        <thead>
          <tr className="bg-gp-blue-50 text-gray-800">
            <th></th>
            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Date & Time</th>
            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Message</th>
          </tr>
        </thead>
        <tbody>
          {
            messages.data?.map((message, i) => <tr className={"hover:bg-gray-200 cursor-pointer" + (message.sendingUserID == "-99" ? " bg-blue-100" : "")}>
              <td className="h-7 w-7">{message.sendingUserID == "-99" ? <ArrowUpTrayIcon /> : <ArrowDownTrayIcon />}</td>
              <td className="px-3 py-3 border text-sm">{message.receiveDate.replace("T", " ").replace("Z", "")}</td>
              <td className="px-3 py-3 border text-sm">{message.body}</td>
            </tr>).reverse()
          }
        </tbody>
      </table>
      <div className="mt-2 py-3 gap-5">
        <h2 className="text-lg font-semibold mb-2">Send a reply</h2>
        <textarea className="min-w-full bg-gp-blue-50 border-gray-300 min-h-[10rem]" value={body} onChange={x => setBody(x.target.value)}></textarea>
        <button className="float-right rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 mt-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700 w-40 h-10" onClick={() => sendMessage()}>
          Send
        </button>
      </div>
    </div>
  </Modal>
}