import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import Modal from "../../layouts/Modal";
import { IPartCategory } from "../../models/Part";
import openInNewTab from "../../utils/openInNewTab";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import PriceFormat from './../../utils/priceFormat';
import BulkUpdateCategoryDefaults from "./BulkUpdate/BulkUpdateCategoryDefaults";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<IPartCategory>();

const cols = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
        <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
        {/* <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button> */}
      </div>
    ),
  },
  colHelper.accessor("name", {
    header: "Name",
    sortingFn: "auto",
  }),
  colHelper.accessor("partOnly", {
    header: "Body Part",
    cell: (info) => <span>{info.getValue() ? "✔" : ""}</span>,
  }),
  colHelper.accessor("mechOnly", {
    header: "Mech Part",
    cell: (info) => <span>{info.getValue() ? "✔" : ""}</span>,
  }),
  colHelper.accessor("frontierName", {
    header: "Frontier Name",
    cell: (info) => <span>{info.getValue() ? "✔" : ""}</span>,
  }),
  colHelper.accessor("pinnacleName", {
    header: "Pinnacle Name",
    cell: (info) => <span>{info.getValue() ? "✔" : ""}</span>,
  }),
  colHelper.accessor("whTime", {
    header: "Warehouse Time",
  }),
  colHelper.accessor("whCost", {
    header: "Warehouse Cost",
    cell: (info) => <span>£{info.getValue()}</span>,
  }),
  colHelper.accessor("disTime", {
    header: "Disassembly Time",
  }),
  colHelper.accessor("disCost", {
    header: "Disassembly Cost",
    cell: (info) => <span>£{info.getValue()}</span>,
  }),
  colHelper.accessor("postage", {
    header: "Postage Cost",
    cell: (info) => <span>{PriceFormat(info.getValue())}</span>,
  }),
  colHelper.accessor("partMappings", {
    header: "# of Mappings",
    sortingFn: "auto",
    cell: (info) => <span>{info.getValue().length}</span>,
  }),
  colHelper.accessor("partCategoryDefaults", {
    header: "Category Defaults",
    cell: (info) => <span>{info.getValue().length > 0 ? "✔" : ""}</span>,
  }),
  colHelper.accessor("partAssemblyPartCategories", {
    header: "Part Assembly",
    cell: (info) => <span>{info.getValue() ? info.getValue()!.length > 0 ? "✔" : "" : ""}</span>,
  }),
];


export default function CategoryTable({ categories, setCategory }: { categories: IPartCategory[], setCategory: any }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedCategories, setSelectedCategories] = useState<IPartCategory[]>([]);
  const [open, setOpen] = useState(false);

  const handleBulkUpdate = () => {
    console.log("Bulk Update");
    const selectCategories = table.getSelectedRowModel().flatRows.map((row) => row.original);

    console.log(selectCategories)
    setSelectedCategories(selectCategories);
    setOpen(true);
  };

  const table = useReactTable({
    data: categories,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <BulkUpdateCategoryDefaults open={open} setOpen={setOpen} selectedCategories={selectedCategories}/>

      <div className="mt-4 space-y-4 overflow-auto">
        <div>
          <ButtonInput label="Bulk Update eBay Defaults" isSubmit={false} disabled={!table.getIsSomeRowsSelected()} onClick={() => handleBulkUpdate()} classes={undefined} />
        </div>
        <table className="min-w-full bg-white overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => openInNewTab(`/wilma/category-editor/${row.original.id}`)}
                    // onClick={() => setCategory(row.original)}
                    className={"hover:bg-gray-100 hover:cursor-pointer"}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <></>
                    // <tr>
                    //   {/* 2nd row is a custom 1 cell row */}
                    //   <td colSpan={row.getVisibleCells().length}>
                    //     <LeadsTableSubComponent lead={row.original} />
                    //   </td>
                    // </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
