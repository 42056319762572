import { Dispatch, SetStateAction, useState } from "react";
import Modal from "../../layouts/Modal";
import LoadingWheel from "../shared/LoadingWheel";
import { useUserLookup } from "../../hooks/useUserLookup";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import useTrips from "../../data/useTrips";
import { ICalendarTrip } from "../../models/Trip";
import { update } from "lodash";
import classNames from "../../utils/classNames";

interface AssignDriverModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  year: number;
  week: number;
  tripId?: string;
}

export function AssignDriverModal({
  open,
  setOpen,
  year,
  week,
  tripId,
}: AssignDriverModalProps) {
  const { isLoading: usersLoading, drivers } = useUserLookup();
  const { updateDriver } = useTrips(year, week);
  const [selectedDriver, setSelectedDriver] = useState<string>("0");

  const handleAssignDriver = () => {
    updateDriver.mutate(
      { tripId, driverId: selectedDriver },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDriver("0");
  };

  return (
    <Modal width="max-w-xl" open={open} setOpen={setOpen}>
      <h1 className="text-xl mb-6">Assign A Driver</h1>
      {usersLoading ? (
        <div className="flex items-center justify-center py-10">
          <LoadingWheel />
        </div>
      ) : (
        <div>
          <select
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.currentTarget.value)}
            aria-label="Driver"
            className="w-full mt-1 text-sm text-gray-900 h-[40px]"
          >
            <option className="sm:text-sm" value="0" selected disabled>
              Select driver...
            </option>
            {drivers?.map((driver) => (
              <option className="sm:text-sm" key={driver.id} value={driver.id}>
                {driver.forename} {driver.surname}
              </option>
            ))}
          </select>
          <ButtonInput
            disabled={selectedDriver === "0"}
            isSubmit={false}
            label="Assign"
            onClick={handleAssignDriver}
            classes="mt-4 w-full"
          />
        </div>
      )}
    </Modal>
  );
}
