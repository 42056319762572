import { DateTime } from "luxon";

import { CameraIcon } from "@heroicons/react/24/outline";

import { IVehicle } from "../../../models/Vehicle";
import formatDateTime from "../../../utils/formatDateTime";
import PriceFormat from "../../../utils/priceFormat";
import Card from "../../shared/Card/Card";
import VehicleIcons from "../../shared/VehicleIcons/VehicleIcons";

const SingleDetail = ({ title, value }: { title: string, value: string | null | undefined }) => {
  return (
    <div className="py-2 grid grid-cols-3 gap-4">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="text-sm text-gray-900 col-span-2">
        {value}
      </dd>
    </div>
  )
}

export default function PartVehicleDetails({ vehicle, price }: { vehicle: IVehicle, price: number | null }) {

  return (

    <Card title="Vehicle Details" bodyClassName="p-4">
      {!vehicle?.found && (
        <div className="mb-4">
          <p className="text-red-400 font-medium text-xl text-center">VEHICLE NOT FOUND</p>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-8 ">

        <div className="col-span-1 flex flex-col justify-center items-center">
          <div className="flex justify-center relative" >
            {vehicle?.vehicleImageUrl ? (
              <>
                <img
                  className="w-40 h-28 object-contain"
                  src={`${vehicle != null ? vehicle.vehicleImageUrl : null}`}
                />
              </>
            ) : (
              <CameraIcon className="w-14 text-gray-600" />
            )}
          </div>
          <div>
            <div className="flex flex-col">
              <SingleDetail title={"Price"} value={price ? PriceFormat(price) : "N/A"} />
              {price && (
                <VehicleIcons vehicle={vehicle} hover />
              )}
            </div>
          </div>
        </div>

        {/* <div className="col-span-1 flex justify-center items-center">
          <div className="flex flex-col">
            <SingleDetail title={"Price"} value={price ? PriceFormat(price) : "N/A"} />
            {price && (
              <VehicleIcons vehicle={vehicle} hover />
            )}
          </div>
        </div> */}

        <dl className="grid col-span-1 lg:col-span-7 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 divide-gray-200">

          <SingleDetail title={"Make/Model"} value={`${vehicle?.combinedMake} ${vehicle?.combinedModel}`} />
          <SingleDetail title={"VRM"} value={vehicle?.vrm} />
          <SingleDetail title={"Year"} value={vehicle?.dvlaYearOfManufacture?.toString()} />
          <SingleDetail title={"Engine Size"} value={`${vehicle?.combinedEngineCapacity}cc`} />
          <SingleDetail title={"Body Type"} value={vehicle?.bodyStyleDescription} />
          <SingleDetail title={"Fuel Type"} value={vehicle?.combinedFuelType} />
          <SingleDetail title={"Colour Code"} value={vehicle?.vinvisColourCode} />
          <SingleDetail title={"Transmission Code"} value={vehicle?.vinvisTransmissionCode} />
          <SingleDetail title={"Transmission"} value={`${vehicle?.combinedForwardGears} Speed ${vehicle?.combinedTransmission}`} />
          <SingleDetail title={"Engine Code"} value={vehicle?.engineModelCode} />
          <SingleDetail title={"Chassis No"} value={vehicle?.combinedVIN} />
          <SingleDetail title={"Mileage"} value={`${vehicle?.odometerInMiles} miles`} />
          <SingleDetail title={"MOT Expiry"} value={formatDateTime(vehicle?.motExpiryDate, DateTime.DATE_SHORT)} />
          <SingleDetail title={"Year Range"} value={`${formatDateTime(vehicle.visibilityDate, { year: "numeric" })} - ${formatDateTime(vehicle.terminateDate, { year: "numeric" })}`} />
          <SingleDetail title={"Salvage Value"} value={PriceFormat(vehicle?.valuationTradePoor)} />  {/* Only newer than 2011 */}
          <SingleDetail
            title={"Catalytic Converter (Min-Avg-Max)"}
            value={(vehicle.catalyticResults !== null && vehicle.catalyticResults.length > 0) ? (
              `£${Math.min(...vehicle.catalyticResults.map(x => x.value))} - £${vehicle.catalyticAveragePrice} - £${Math.max(...vehicle.catalyticResults.map(x => x.value))}`
            ) : (
              "Unavailable"
            )}
          />


          {/* <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Starts and Drives?</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {vehicle?.vehicleStarting ? "Yes" : "No"}
            </dd>
          </div> */}

        </dl>

      </div>


    </Card>
  )

}