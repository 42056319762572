import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useEbayMessages from "../../data/useEbayMessages";
import LoadingWheel from "../../components/shared/LoadingWheel";
import EbayMessageChain from "./EbayMessageChain";
import { IEbayMessage } from "../../models/EbayMessage";
import formatDateTime from './../../utils/formatDateTime';
import { DateTime } from "luxon";

export default function EbayMessages() {
    const { setInfo } = usePageNameContext();
    const [page, setPage] = useState(1);
    const [days, setDays] = useState(7);
    const { unread, allmsgs } = useEbayMessages({ page, setPage, days, setDays });
    const [unreadOnly, setUnreadOnly] = useState(true);
    const [chainOpen, setChainOpen] = useState(false);
    const [chosenMessage, setChosenMessage] = useState<IEbayMessage | undefined>();


    useEffect(() => {
        setInfo({
            name: "View eBay Messages",
            desc: "View and manage messages to customers.",
        });
    }, []);

    var msgs = unreadOnly ? unread : allmsgs;

    if (msgs.isLoading)
        return <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
            <LoadingWheel />
        </div>;

    return <>
        <EbayMessageChain open={chainOpen} setOpen={setChainOpen} originMessage={chosenMessage} />
        <div className="mt-4 flex flex-col justify-between">
            <div>
                <label>Unread Only </label>
                <input type="checkbox" checked={unreadOnly} onChange={x => setUnreadOnly(x.target.checked)} className="cursor-pointer" />
            </div>
            <div>
                <label>Days to display </label>
                <select onChange={x => { setDays(parseInt(x.target.value)); msgs.refetch(); }} value={days}>
                    <option value={1}>1 Day</option>
                    <option value={7}>1 Week</option>
                    <option value={14}>2 Weeks</option>
                    <option value={30}>1 Month</option>
                    {/* <option value={60}>2 Months</option>
                    <option value={90}>3 Months</option>
                    <option value={190}>6 Months</option>
                    <option value={365}>1 Year</option> */}
                </select>
                {/* <input id="number" type="number" value={days} onChange={x=>{setDays(parseInt(x.target.value)); msgs.refetch();}} /> */}
            </div>
            <div className="mt-4 bg-white overflow-auto">
                <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
                    <thead>
                        <tr className="bg-gp-blue-50 text-gray-800">
                            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Received At</th>
                            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Sender</th>
                            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Listing Name</th>
                            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Message Text</th>
                            {unreadOnly ? null :
                                <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Replied</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            msgs.data?.map((x, i) => <tr className={"hover:bg-gray-200 cursor-pointer" + (x.read != "True" && !unreadOnly ? " bg-blue-100" : "")}
                                onClick={() => { setChosenMessage(x); setChainOpen(true); }}>
                                <td className="px-3 py-3 border text-sm">{formatDateTime(x.receiveDate, DateTime.DATETIME_SHORT)}</td>
                                <td className="px-3 py-3 border text-sm">{x.sendingUserID == "-99" ? "gpmetalsandspares" : x.sendingUserID}</td>
                                <td className="px-3 py-3 border text-sm">{x.itemTitle}</td>
                                <td className="px-3 py-3 border text-sm">{x.body}</td>
                                {unreadOnly ? null :
                                    <td className="px-3 py-3 border text-sm text-center">
                                        <input type="checkbox" checked={x.read == "True"} readOnly={true} />
                                    </td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
            <div className="text-3xl text-center">
                <button className="pr-2" onClick={() => { setPage(page - 1); msgs.refetch(); }}>&lt;</button>
                <a className="text-xl">{page}</a>
                <button className="pl-2" onClick={() => { setPage(page + 1); msgs.refetch(); }}>&gt;</button>
            </div>
        </div>
    </>
}