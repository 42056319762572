import { useForm } from "react-hook-form";

import { UseMutationResult } from "@tanstack/react-query";

import useMappings from "../../data/useMappings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { IPartCategory, IPartMapping } from "../../models/Part";

import { useEffect, useState } from "react";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import { NumberInput } from "../../components/shared/Inputs/NumberInput";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import CategoryDetails from "../../components/categoryEditor/CategoryDetails";
import { CategoryListingDefaults } from "../../components/categoryEditor/CategoryListingDefaults";
import CategoryAspectsDefaults from "../../components/categoryEditor/CategoryAspectsDefaults";
import { useParams } from "react-router-dom";
import useCategorySingle from "../../data/useCategorySingle";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";


type MappingFormValues = {
  regex: string,
  priority: number
};

interface EditCategoryProps {
  open: boolean,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  category: IPartCategory | undefined,
  update: UseMutationResult<IPartCategory, unknown, IPartCategory, unknown>,
  create: UseMutationResult<IPartCategory, unknown, IPartCategory, unknown>
};

function MappingRow({ mapping, categoryId, setOpen }: { mapping: IPartMapping | null, categoryId: string, setOpen?: any }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Part Mapping");
  const { create, update } = useMappings();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm<MappingFormValues>({
    defaultValues: {
      regex: mapping?.regex,
      priority: mapping?.priority,
    }
  });

  const onSubmit = (data: MappingFormValues) => {
    console.log(data);

    const updatedMapping = {
      partCategoryId: categoryId,
      ...mapping!,
      ...data,
    };

    if (mapping) {
      update.mutate(updatedMapping, saveHandlers);
    } else {
      create.mutate(updatedMapping, saveHandlers);
      reset({});
      setOpen && setOpen(false);
    }

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-7 gap-x-6">
        <div className="col-span-4">
          <TextInput label="Regex" registerName="regex" register={register} />
        </div>
        <div className="col-span-2">
          <NumberInput label="Priority" registerName="priority" step="1" register={register} />
        </div>
        <ButtonInput label={mapping ? "Save" : "Create"} isSubmit={true} onClick={undefined} classes={"mt-7"} />
      </div>
    </form>
  )
};


export default function EditCategory() {
  const { categoryId } = useParams();
  const { setInfo } = usePageNameContext();
  const { category, create, update } = useCategorySingle(categoryId);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!category.isLoading && category.data) {
      setIsLoading(false);
    }
  }, [category.isLoading, category.data])

  useEffect(() => {
    setInfo({
      name: "Edit Category",
      desc: "Edit Category Defaults and Mappings",
    });
  }, [])

  if (isLoading) {
    return <LoadingWheel />
  }

  return (
    <div>

      <div className="flex justify-between items-center">
        <h1 className="text-xl">{`${!category ? "Add a category" : ""}`}</h1>
        {category && <ButtonInput label="View Parts" isSubmit={false} onClick={() => window.open(`/wilma/parts-editor/${category?.data?.data.id}`, "_blank", "noreferrer")} classes={undefined} />}
      </div>
      <div className="mt-4">
        <CategoryDetails category={category.data?.data} update={update} create={create} />
        <br />
        {category &&
          <CategoryListingDefaults category={category.data?.data} />
        }

        {category?.data?.data.partCategoryDefaults[0]?.ebayCategoryId && (
          <CategoryAspectsDefaults category={category.data?.data} />
        )}

        {category &&
          <div className="w-full mt-4 space-y-4">
            <h1 className="text-lg">Part Mappings <span className="text-sm"> - Smallest number is highest priority</span></h1>
            <hr />
            {category?.data?.data.partMappings.map((mapping) => (
              <MappingRow key={mapping.id} mapping={mapping} categoryId={category.data.data.id} />
            ))}
            <MappingRow mapping={null} categoryId={category.data?.data.id!} />
          </div>
        }
      </div>

    </div>
  );
};
