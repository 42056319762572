import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IGetCalendarTripsResponse } from "../models/Trip";

export default function useTrips(year: number, week: number) {
  const { get, post, put, patch, del } = useApiHelper();
  const queryClient = useQueryClient();

  const trips = useQuery({
    queryKey: ["trips", year, week],
    queryFn: () => get<IGetCalendarTripsResponse>(`/trip/${year}/${week}`),
  });

  const getCalendarTrips = useMutation(
    (times: any) => get(`/trip/${times.year}/${times.week}`),
    {
      onSuccess: (data: any, variables: any, context: any) => {
        return data;
      },
    }
  );

  const create = useMutation((body: any) => post(`/trip`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(["trips", year, week]);
    },
  });

  const update = useMutation(
    (body: any) => patch(`/trip/${body.tripId}/drop`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips", year, week]);
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const updateTimeslot = useMutation(
    (body: any) => put(`/trip/${body.tripId}/timeslot`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips", year, week]);
      },
    }
  );

  const updateDriver = useMutation(
    (body: any) =>
      patch(`/trip/${body.tripId}/assignTripManager/${body.driverId}`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trips", year, week]);
      },
    }
  );

  const remove = useMutation((id: string) => del(`/trip/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["trips", year, week]);
    },
  });

  return {
    trips,
    getCalendarTrips,
    create,
    update,
    updateTimeslot,
    updateDriver,
    remove,
  };
}
