import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RadioGroup } from "@headlessui/react";

import LeadContactDetails from "../../components/contacts/LeadContactDetails";
import LeadDocuments from "../../components/leads/LeadDocuments";
import DriverNotes from "../../components/leads/LeadInformation/DriverNotes";
import LeadIdentityImages from "../../components/leads/LeadInformation/LeadIdentityImages";
import LeadPaymentDetails from "../../components/leads/LeadInformation/LeadPaymentDetails";
import LeadStatusCard from "../../components/leads/LeadInformation/LeadStatusCard";
import OfficeNotes from "../../components/leads/LeadInformation/OfficeNotes";
import VehicleNotes from "../../components/leads/LeadInformation/VehicleNotes";
import LeadParts from "../../components/leads/LeadParts/LeadParts";
import LeadSMS from "../../components/leads/LeadSMS";
import useNotification from "../../components/notifications/useNotifications";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import ConfirmModal from "../../components/shared/Confirm/ConfirmModal";
import LoadingWheel from "../../components/shared/LoadingWheel";
import EnginesWorldwideDetails from "../../components/vehicles/EnginesWorldwideDetails";
import VehicleDetails from "../../components/vehicles/VehicleDetails";
import VehicleLocation from "../../components/vehicles/VehicleLocation";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useSingleLead from "../../data/useSingleLead";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../models/Lead";
import classNames from "../../utils/classNames";
import getAddress from "../../utils/getAddress";

export default function Lead() {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  const { setInfo } = usePageNameContext();
  const { leadId, tab } = useParams();
  const { lead: singleLead, remove } = useSingleLead(leadId!);
  const [lead, setLead] = useState<ILead | undefined>();
  const [loading, setLoading] = useState(true);
  const [leadName, setLeadName] = useState<string | undefined>("");
  const [open, setOpen] = useState(false);
  const [viewType, setViewType] = useState(0);
  const viewTypes = [
    { value: 0, label: "Lead Information" },
    { value: 1, label: "Documents" },
    { value: 2, label: "SMS/Email" },
    { value: 3, label: "Parts" },
  ];

  const handleDelete = () => {
    remove.mutate(leadId!, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        navigate(-1);
      },
      onError: (error) => {
        console.log(error);
        addNotification({
          variant: "error",
          primaryText: error,
        });
      },
    });
  };

  useEffect(() => {
    if (leadName) {
      setInfo({
        name: `${leadName}'s Lead ${lead?.vehicle?.vrm ? ` - ${lead.vehicle.vrm}` : ""
          }`,
        desc: `View and manage ${leadName}'s Lead`,
      });
    }
  }, [leadId, leadName]);

  useEffect(() => {
    if (!singleLead.isLoading) {

      if (singleLead.data?.data) {
        // console.log(singleLead.data?.data);
        if (tab == "parts") {
          setViewType(3);
        }
        setLead(singleLead.data?.data);
        setLeadName(singleLead.data?.data.contact?.fullname);
        setLoading(false);
      } else {
        addNotification({
          variant: "error",
          primaryText: "Lead not found",
        });
        navigate(-1);
      }
    }
  }, [leadId, singleLead.isLoading, singleLead.data?.data, navigate]);

  const pages = [
    { to: "..", label: "Leads", current: false },
    {
      to: leadId && lead ? `/leads/${leadId}` : "",
      label: leadId && lead ? `Lead for ${lead.contact?.fullname}` : "New Lead",
      current: true,
    },
  ];

  return loading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <ConfirmModal
        title="Delete Lead?"
        message="Are you sure you want to delete this lead? This is a permanent action."
        confirmButtonText={null}
        onConfirm={handleDelete}
        open={open}
        setOpen={setOpen}
        isLoading={false}
      />
      <Breadcrumbs pages={pages} />

      <RadioGroup value={viewType} onChange={setViewType} className="my-4">
        <RadioGroup.Label className="sr-only">
          Choose which view to display
        </RadioGroup.Label>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
          {viewTypes.map((type) => (
            <RadioGroup.Option
              key={`${type.value}`}
              value={type.value}
              className={({ active, checked }) =>
                classNames(
                  active ? "ring-2 ring-offset-2 ring-gp-blue-500" : "",
                  checked
                    ? "bg-gp-blue-600 border-transparent text-white hover:bg-gp-blue-700"
                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                  "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">{type.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {{
        0: (
          <div className="mt-4 flex flex-col md:grid md:grid-cols-3 gap-6">
            {/* Left hand thin column */}
            <div className="col-span-1 gap-6 flex flex-col">
              <div className="col-span-1 bg-white rounded-lg shadow">
                <iframe
                  className="min-h-[50vh]"
                  title="Google map"
                  style={{ border: 0, borderRadius: "0.5rem" }}
                  loading="lazy"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAJWEQovZtn6KkghJ5z6maiL0G6KHjwbS4&q=${getAddress(
                    lead?.vehicle
                  )}&zoom=9`}
                  width="100%"
                  height="100%"
                ></iframe>
              </div>

              <VehicleDetails vehicle={lead?.vehicle!} />
              {lead?.vehicle?.enginesWorldwide.map((offer) => <EnginesWorldwideDetails offer={offer} />)}
            </div>

            {/* Right hand wide column */}
            <div className="col-span-2 gap-6 flex flex-col">
              <LeadContactDetails lead={lead!} />
              <VehicleLocation vehicle={lead?.vehicle!} />
              <LeadStatusCard leadId={lead?.id!} />
              <LeadIdentityImages lead={lead!} />
              <LeadPaymentDetails lead={lead!} />
            </div>
            <div>
              <OfficeNotes lead={lead!} />
            </div>
            <div>
              <DriverNotes lead={lead!} />
            </div>
            <div>
              <VehicleNotes lead={lead!} />
              <button
                className="mt-6 whitespace-nowrap float-right rounded-md border border-transparent bg-red-600 px-3 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700"
                type="button"
                onClick={() => setOpen(true)}
              >
                Delete Lead
              </button>
            </div>
          </div>
        ),
        1: <LeadDocuments lead={lead!} />,
        2: <LeadSMS lead={lead!} />,
        3: <LeadParts lead={lead!} vehicle={null} />,
      }[viewType]}
    </>
  );
}
