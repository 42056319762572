import { useMutation, useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ITellerooAccountRespose } from "../models/Utilities";

export default function useUtilities() {
  const { get } = useApiHelper();

  const getTellerooAccount = useQuery({
    queryKey: ["tellerooAccount"],
    queryFn: () => get<ITellerooAccountRespose>("/utilities/telleroo/account"),
    staleTime: 1000 * 60 * 5, // 5 minute
  });

  const getAddresses = useMutation(
    (postcode: string) => get(`/utilities/address/${postcode}`),
    {
      onSuccess: () => {
        
      },
    }
  );

  return {
    getTellerooAccount,
    getAddresses
  };
}
