import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import {ICategoryPartResponse, IPart} from "../models/Part";
import {IPromotion, IPromotionCollection} from "../models/Promotion";
import {DefaultResponseWithData, EbayResponseWrapper} from "../models/system";
import {ITruck} from "../models/Truck";
import { IEbayMessage } from "../models/EbayMessage";

interface EbayMessagesProps {
  page: number;
  setPage: any;
  days: number;
  setDays: any;
}

export default function useEbayMessages({page, setPage, days, setDays}: EbayMessagesProps) {
  const { get, put } = useApiHelper();
  const queryClient = useQueryClient();

  const unread = useQuery({
    queryKey: ["ebayunreadmessages", page, days],
    queryFn: () => get<IEbayMessage[]>(`/ebaymessaging/unread/${page}?daysToSearch=${days}`),
  });

  const allmsgs = useQuery({
    queryKey: ["ebayallmessages", page, days],
    queryFn: () => get<IEbayMessage[]>(`/ebaymessaging/messages/${page}?daysToSearch=${days}`),
  });

  // const applyPromotion = useMutation(
  //   ({part, promo}: {part: IPart | undefined, promo: IPromotion}) => put(`/ebaysafe/promotions/additem?promotionId=${promo.promotionId}&listingSKU=${part?.id}`, null),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["promotions"]);
  //     },
  //   }
  // );

  return {
    unread,
    allmsgs
  };
}
