import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useDrop } from "react-dnd";

import { MinusIcon } from "@heroicons/react/24/outline";

import LeadEvent from "./LeadEvent";
import useLeads from "../../data/useLeads";
import useTrips from "../../data/useTrips";
import TimeSlotSelect from "./TimeSlotSelect";
import classNames from "../../utils/classNames";
import { ICalendarTrip } from "../../models/Trip";
import { DragTypes } from "../../models/DragTypes";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { IAllLeadsResponse, ILead } from "../../models/Lead";
import { Distances } from "../../pages/Calendar/TripManager";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

type IProps = {
  trip: ICalendarTrip;
  date: string;
  capacity: number;
  distances: Distances | undefined;
  selectedTrip?: string;
  setSelectedTrip?: any;
  handleSelected?: any;
  unavailable: boolean;
  year?: number;
  week?: number;
  leadsFromIds: IAllLeadsResponse | undefined;
  onDriverAvatarClick: (tripId: string) => void;
};

export default function TripSlot({ onDriverAvatarClick, ...props }: IProps) {
  const { update } = useLeads(true);
  const {
    update: updateTrip,
    remove,
    updateTimeslot,
  } = useTrips(props.year!, props.week!);
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  const [leads, setLeads] = useState<ILead[]>([]);
  const [removeOpen, setRemoveOpen] = useState(false);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DragTypes.EVENT,
      drop: (e: ILead) => handleDroppedLead(e),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.trip]
  );

  const handleDroppedLead = (lead: ILead) => {
    // console.log("dropped lead", lead)

    if (lead === undefined) {
      saveHandlers.onError();
      return;
    }

    let updatedTrip = {
      leadId: lead.id,
      tripId: props.trip.id,
      driverId: props.trip.driverId,
      scheduledCollectionOn: props.date,
      order: leads.length,
    };

    updateTrip.mutate(updatedTrip, {
      onSuccess: () => {
        props.handleSelected(undefined);
        saveHandlers.onSuccess();
      },
      onError: saveHandlers.onError,
    });
  };

  const handleColour = () => {
    let background = "bg-gray-50";

    if (props.trip.id == props.selectedTrip) background = "bg-green-200";

    if (props.unavailable) background = "bg-gray-300";

    if (isOver) background = "bg-gp-blue-200";

    return background;
  };

  const handleRemoveTrip = () => {
    remove.mutate(props.trip.id, {
      onSuccess: () => {
        setRemoveOpen(false);
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      },
    });
  };

  const handleOrderChange = (up: boolean, lead: ILead) => {
    let newOrder = lead.order;

    //Manage the order of the lead that was moved
    if (up) {
      newOrder = lead.order - 1;
      if (newOrder < 0) return;
    } else {
      newOrder = lead.order + 1;
      if (newOrder > leads.length! - 1) return;
    }

    //Get the lead that is currently in the newOrder position
    let leadInNewOrder = leads.find((l: ILead) => l.order === newOrder);

    //Update the lead that was moved
    update.mutate(
      { ...lead, order: newOrder },
      {
        onSuccess: () => {
          //Update the lead that was in the newOrder position
          if (leadInNewOrder !== undefined) {
            update.mutate(
              { ...leadInNewOrder!, order: lead.order },
              saveHandlers
            );
          } else {
            saveHandlers.onSuccess();
          }
        },
        onError: saveHandlers.onError,
      }
    );
  };

  const handleTimeSlotChange = (
    value: DateTime | null,
    duration: number | null
  ) => {
    console.log(value, duration);
    updateTimeslot.mutate({
      tripId: props.trip.id,
      timeSlotStart: value?.toISO(),
      duration: duration,
    });
  };

  useEffect(() => {
    if (props.leadsFromIds) {
      let leads = props.leadsFromIds?.data.leads.filter((lead: ILead) =>
        props.trip.leads?.includes(lead.id)
      );
      setLeads(
        leads.sort((a: ILead, b: ILead) => (a.order > b.order ? 1 : -1))
      );
    }
  }, [props.leadsFromIds, props.trip, props.date]);

  return (
    <>
      <ConfirmModal
        open={removeOpen}
        setOpen={setRemoveOpen}
        title={"Remove Trip"}
        message={"Are you sure you would like to remove this trip?"}
        confirmButtonText={"Remove"}
        onConfirm={handleRemoveTrip}
        isLoading={false}
      />
      <div
        className={classNames(handleColour(), "w-60 relative p-1 h-full")}
        onClick={() => props.setSelectedTrip(undefined, props.trip.id)}
        ref={drop}
        style={{
          opacity: isOver ? 0.8 : 1,
        }}
      >
        {leads?.length > 0 ? (
          <div className="h-full flex flex-col justify-between text-right">
            <div>
              {leads?.map((lead: ILead) => {
                return (
                  <LeadEvent
                    key={lead.id}
                    lead={lead!}
                    distances={props.distances}
                    handleSelected={() => {}}
                    inGroup={leads?.length}
                    handleOrderChange={handleOrderChange}
                  />
                );
              })}
              <span className="w-full px-2 text-gray-400">{`${leads.length} / ${props.capacity}`}</span>
            </div>
            <div>
              <TimeSlotSelect
                trip={props.trip}
                timeSlotChange={handleTimeSlotChange}
                onDriverAvatarClick={() => onDriverAvatarClick(props.trip.id)}
              />
            </div>
          </div>
        ) : (
          <div className="group relative flex flex-col justify-center items-center h-full min-h-10">
            <div className="flex flex-1 flex-col justify-center items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setRemoveOpen(true);
                }}
                className="hidden group-hover:block text-2xl bg-red-500 rounded-full text-white hover:bg-red-700"
              >
                <MinusIcon className="h-10 w-10" aria-hidden="true" />
              </button>
              <span className="absolute top-0 right-2 text-gray-400">{`${leads.length} / ${props.capacity}`}</span>
            </div>
            <div className="w-full">
              <TimeSlotSelect
                trip={props.trip}
                timeSlotChange={handleTimeSlotChange}
                onDriverAvatarClick={() => onDriverAvatarClick(props.trip.id)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
