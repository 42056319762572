import { CameraIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import useVehicles from "../../data/useVehicles";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { IVehicle } from "../../models/Vehicle";
import Card from "../shared/Card/Card";
import useVehiclesMutations from "../../data/useVehiclesMutations";
import formatDateTime from "../../utils/formatDateTime";
import { DateTime } from "luxon";
import PriceFormat from "../../utils/priceFormat";


const SingleDetail = ({ title, value, textColour = "text-gray-900" }: { title: string, value: string | null | undefined, textColour?: string }) => {
  return (
    <div className="py-2 grid grid-cols-3 gap-4">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className={`${textColour} text-sm col-span-2`}>
        {value}
      </dd>
    </div>
  )
}

export default function VehicleDetails({ vehicle, driver = false }: { vehicle: IVehicle, driver?: boolean }) {
  const { vrmRefresh } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Vehicle");
  const [open, setOpen] = useState(false);
  const [vrm, setVrm] = useState(vehicle.vrm);

  useEffect(() => {

  }, [vehicle])

  const handleUpdateVrm = () => {
    //Uppercase and remove whitespace from vrm
    const updatedVehicle = {
      ...vehicle,
      vrm: vrm.toUpperCase().replace(/\s/g, "")
    };

    vrmRefresh.mutate(updatedVehicle, {
      onSuccess: () => {
        setOpen(false);
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      }
    })
  }

  return (
    <>
      <Modal open={open} setOpen={setOpen} width="max-w-xl">
        <label>Enter VRM to search for:</label>
        <input defaultValue={vehicle.vrm} onChange={(e) => setVrm(e.target.value)} className="my-5 w-full text-sm" type="text" />
        <button
          onClick={() => handleUpdateVrm()}
          className="float-right rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
          Save
        </button>
      </Modal>
      <Card title="Vehicle Details" bodyClassName="p-4">
        <dl className="divide-y divide-gray-200">
          {!vehicle.found && (
            <div className="mb-4">
              <p className="text-red-400 font-medium text-xl text-center">VEHICLE NOT FOUND</p>

              <div className="flex justify-center w-full mt-2">
                <button
                  onClick={() => { setOpen(true) }}
                  className="rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
                >
                  Find Vehicle
                </button>
              </div>
            </div>
          )}

          <SingleDetail title="Make/Model" value={`${vehicle.combinedMake} ${vehicle.combinedModel}`} />

          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">VRM</dt>
            <dd className="flex justify-between items-center text-sm text-gray-900 col-span-2">
              <div>{vehicle.vrm}</div>
              {!driver && (
                <button
                  onClick={() => setOpen(true)}
                  className="inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-1 py-1 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700">
                  Update
                </button>
              )}
            </dd>
          </div>

          {vehicle.found && (
            <SingleDetail title={"Year"} value={vehicle?.dvlaYearOfManufacture?.toString()} />
          )}

          {!driver && vehicle.found && (
            <>
              <SingleDetail title={"Engine Size"} value={`${vehicle?.combinedEngineCapacity}cc`} />
              <SingleDetail title={"Body Type"} value={vehicle?.bodyStyleDescription} />
              <SingleDetail title={"Fuel Type"} value={vehicle?.combinedFuelType} />
              <SingleDetail title={"Colour Code"} value={vehicle?.vinvisColourCode} />
              <SingleDetail title={"Transmission Code"} value={vehicle?.vinvisTransmissionCode} />
              <SingleDetail title={"Transmission"} value={`${vehicle?.combinedForwardGears} Speed ${vehicle?.combinedTransmission}`} />
              <SingleDetail title={"Engine Code"} value={vehicle?.engineModelCode} />
              <SingleDetail title={"Chassis No"} value={vehicle?.combinedVIN} />
              <SingleDetail title={"Mileage"} value={`${vehicle?.odometerInMiles} miles`} />
              {vehicle?.odometerInMiles && vehicle?.dvlaYearOfManufacture &&
                (<SingleDetail
                  title={"Miles/Year"}
                  value={(vehicle?.odometerInMiles / (DateTime.now().year - vehicle?.dvlaYearOfManufacture)).toFixed(2).toString()}
                  textColour={(vehicle?.odometerInMiles / (DateTime.now().year - vehicle?.dvlaYearOfManufacture)) < 8000 ? "text-green-700 font-bold" : "text-gray-900" } />
                )}
              <SingleDetail title={"MOT Expiry"} value={formatDateTime(vehicle?.motExpiryDate, DateTime.DATE_SHORT)} />
              <SingleDetail title={"Year Range"} value={`${formatDateTime(vehicle.visibilityDate, { year: "numeric" })} - ${formatDateTime(vehicle.terminateDate, { year: "numeric" })}`} />
              <SingleDetail title={"Kerb Weight"} value={vehicle.kerbWeightMin ? (
                `${vehicle.kerbWeightMin} ${vehicle.kerbWeightMax ? ` - ${vehicle.kerbWeightMax}` : ""} kg`
              ) : (
                "Unavailable"
              )} />

              <SingleDetail title={"Salvage Value"} value={PriceFormat(vehicle?.valuationTradePoor)} />  {/* Only newer than 2011 */}
              <SingleDetail
                title={"Catalytic Converter (Min-Avg-Max)"}
                value={(vehicle.catalyticResults !== null && vehicle.catalyticResults.length > 0) ? (
                  `£${Math.min(...vehicle.catalyticResults.map(x => x.value))} - £${vehicle.catalyticAveragePrice} - £${Math.max(...vehicle.catalyticResults.map(x => x.value))}`
                ) : (
                  "Unavailable"
                )}
              />

              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-red-500">Engine Price</dt>
                <dd className="text-sm font-medium text-red-500 col-span-2">
                  {vehicle.enginesWorldwideEngineOffer ?
                    new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(parseFloat(vehicle.enginesWorldwideEngineOffer))
                    : "N/A"
                  }
                </dd>
              </div>
              <div className="py-2 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-red-500">Front End</dt>
                <dd className="text-sm font-medium text-red-500 col-span-2">
                  {vehicle.enginesWorldwideVfeOffer ?
                    new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(parseFloat(vehicle.enginesWorldwideVfeOffer))
                    : "N/A"
                  }
                </dd>
              </div>
            </>
          )}

          <div className="py-4 flex items-center justify-center bg-white rounded-lg">
            {vehicle.vehicleImageUrl ? (
              <img
                className="w-full"
                src={`${vehicle.vehicleImageUrl}`}
              />
            ) : (
              <CameraIcon className="w-1/2 text-gray-600" />
            )}
          </div>

        </dl>
      </Card>
    </>
  );
}
