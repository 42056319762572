import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import {ICategoryPartResponse, IPart} from "../models/Part";
import {IPromotion, IPromotionCollection} from "../models/Promotion";
import {DefaultResponseWithData, EbayResponseWrapper} from "../models/system";
import {ITruck} from "../models/Truck";
import { IEbayMessage } from "../models/EbayMessage";

export default function useEbayMessageChain(originMessage: IEbayMessage | undefined) {
  const { get, post } = useApiHelper();
  const queryClient = useQueryClient();

  const shouldFetch = originMessage != undefined;

  const messages = useQuery(
    ["ebaychainmessages", originMessage?.itemID, originMessage?.sendingUserID],
    () => get<IEbayMessage[]>(`/ebaymessaging/messagechain/${originMessage?.itemID}/${originMessage?.sendingUserID}`),
    {
      enabled: shouldFetch
    }
  );

  const sendReply = useMutation(
    ({body}: {body: string}) => {
      var last = messages.data ? messages.data[messages.data.length-1] : null;
      return post(`/ebaymessaging/messagechain/send/${last?.itemID}/${last?.sendingUserID}/${last?.messageID}`, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ebaychainmessages", originMessage?.itemID, originMessage?.sendingUserID]);
      }
    }
  )

  // const applyPromotion = useMutation(
  //   ({part, promo}: {part: IPart | undefined, promo: IPromotion}) => put(`/ebaysafe/promotions/additem?promotionId=${promo.promotionId}&listingSKU=${part?.id}`, null),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["promotions"]);
  //     },
  //   }
  // );

  return {
    messages,
    sendReply
  };
}
