import { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { DocumentArrowDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import ExportLeads from "../../components/leads/LeadTable/ImportExport/ExportLeads";
import ImportLeads from "../../components/leads/LeadTable/ImportExport/ImportLeads";
import LeadsTable from "../../components/leads/LeadTable/LeadsTable";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useMessages from "../../data/useMessages";
import Modal from "../../layouts/Modal";
import { ILead, LeadSource, LeadStatus } from "../../models/Lead";
import { DateTime } from "luxon";
import useLeadsWithFilters from "../../data/useLeadsWithFilters";
import { useDebounce } from "@uidotdev/usehooks";

export default function Leads() {
  const { setInfo } = usePageNameContext();
  const { unreadMessages } = useMessages();
  const [searchParams] = useSearchParams();
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [exportOpen, setExportOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const [showUnreadQueryString, setShowUnreadQueryString] = useState<boolean>(searchParams.get("unread") === "1" ? true : false);
  const [showRescheduleQueryString, setRescheduleQueryString] = useState<boolean>(searchParams.get("reschedule") === "1" ? true : false);
  // const [usedQuery, setUsedQuery] = useState<boolean>(searchParams.get("unread") === "1" ? false : true);
  const [isLoading, setIsLoading] = useState(true);
  const [lastExported, setLastExported] = useState<string>("");
  const [lastSccImport, setLastSccImport] = useState<ILead | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentStatusFilter, setCurrentStatusFilter] = useState("Awaiting Contact");
  const [currentStatusFilterIdx, setCurrentStatusFilterIdx] = useState(0);
  const [currentWebsiteFilter, setCurrentWebsiteFilter] = useState("All Sources");
  const [currentWebsiteFilterIdx, setCurrentWebsiteFilterIdx] = useState(5);
  const leadsTableRef = useRef();

  const refreshTriggers = useDebounce([searchTerm, currentStatusFilterIdx, currentWebsiteFilterIdx], 500);

  const { leadsWithFilters } = useLeadsWithFilters({ query: refreshTriggers[0] as string, status: refreshTriggers[1] as number, website: refreshTriggers[2] as number });

  const statusFilters = [
    ...Object.values(LeadStatus).filter((ct) => isNaN(Number(ct))),
  ];

  const websiteFilters = [
    ...Object.values(LeadSource).filter((ct) => isNaN(Number(ct))),
    "All Sources"
  ]

  useEffect(() => {
    setInfo({
      name: "Leads",
      desc: "View and manage your leads here",
    });
  }, []);

  useEffect(() => {
    if (!leadsWithFilters.isLoading && !unreadMessages.isLoading) {

      let filter = leadsWithFilters.data?.data.leads;

      if (showUnread || showUnreadQueryString) {
        if (showUnreadQueryString && !showUnread) {
          setShowUnread(true);
        }
        setCurrentStatusFilter("All");
        setCurrentStatusFilterIdx(9);
        filter = filter?.filter((lead) => unreadMessages.data?.data.find(m => m.leadId === lead.id)?.unreadMessageCount! > 0);
        setShowUnreadQueryString(false);
      } else if (showRescheduleQueryString) {
        setCurrentStatusFilter("Reschedule");
        setCurrentStatusFilterIdx(LeadStatus.Reschedule);
        setRescheduleQueryString(false);
      }

      //find any lead with an unread message or premium and move to the top
      const topLeads = filter?.filter((lead) => unreadMessages.data?.data.find(m => m.leadId === lead.id)?.unreadMessageCount! > 0 || lead.vehicle?.vehiclePremium);
      const restOfLeads = filter?.filter((lead) => unreadMessages.data?.data.find(m => m.leadId === lead.id)?.unreadMessageCount! === 0 && !lead.vehicle?.vehiclePremium);

      filter = topLeads?.concat(restOfLeads!);

      setLeads(filter);
      setLastExported(leadsWithFilters.data?.data.lastExported ?? "Never");
      //Get the last SCC import

      const filteredLeads = leadsWithFilters.data?.data.leads.filter((lead) => lead.website === 1);
      setLastSccImport(filteredLeads == null || filteredLeads.length == 0 ? {} as ILead : filteredLeads.reduce((a, b) => (a.created > b.created ? a : b)) ?? undefined)
      setIsLoading(false);
    }
  }, [leadsWithFilters.isLoading, leadsWithFilters.data, unreadMessages.isLoading, unreadMessages.data, showUnread]);

  return <>
    <Modal open={exportOpen} setOpen={setExportOpen} width="sm:max-w-3xl">
      <ExportLeads
        onCancel={() => setExportOpen(false)}
        onExport={() => console.log()}
      />
    </Modal>
    <Modal open={importOpen} setOpen={setImportOpen} width="sm:max-w-3xl">
      <ImportLeads
        onCancel={() => setImportOpen(false)}
        onImport={() => console.log()}
      />
    </Modal>

    <div className="mt-4">
      <div>
        <p className="text-sm text-gray-500">
          Last SCC import: {lastSccImport?.created ? DateTime.fromISO(lastSccImport?.created).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) : "Never"}
        </p>
      </div>
    </div>

    <div className="mt-2 flex justify-between">
      <div className="flex gap-x-5">
        <select aria-label="Lead status filter"
          onChange={(e) => { setCurrentStatusFilter(e.target.value); setCurrentStatusFilterIdx(e.target.selectedIndex) }}
          value={currentStatusFilter}
          className="sm:text-sm">
          {statusFilters.map((filter) => (
            <option
              className="sm:text-sm"
              key={filter}>
              {String(filter)}
            </option>
          ))}
        </select>

        <select aria-label="Lead website filter"
          onChange={(e) => { setCurrentWebsiteFilter(e.target.value); setCurrentWebsiteFilterIdx(e.target.selectedIndex); }}
          value={currentWebsiteFilter}
          className="sm:text-sm">
          {websiteFilters.map((filter) => (
            <option
              className="sm:text-sm"
              key={filter}>
              {String(filter)}
            </option>
          ))}
        </select>

        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 sm:text-sm"
            placeholder="Name, VRM or Postcode"
          />
        </div>

        <div className="flex items-center">
          <input id="showUnread" onChange={(e) => setShowUnread(e.target.checked)} value="showUnread" checked={showUnread} type="checkbox" className="mr-2 h-5 w-5" />
          <label htmlFor="showUnread">Show Unread</label>
        </div>

      </div>
      <div className="flex items-center">
        <button
          onClick={() => setExportOpen(true)}
          className="inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
        >
          <DocumentArrowDownIcon
            className="-ml-0.5 mr-2 h-4 w-4"
            aria-hidden="true"
          />
          Export to CSV
        </button>
        <button
          onClick={() => setImportOpen(true)}
          className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
        >
          <DocumentArrowDownIcon
            className="-ml-0.5 mr-2 h-4 w-4"
            aria-hidden="true"
          />
          Import from CSV
        </button>
      </div>
    </div>
    {
      leadsWithFilters.isLoading || isLoading ? (
        <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
          <LoadingWheel />
        </div>
      ) : (<LeadsTable data={leads!} />)
    }
  </>;
}
